
import { Component, Vue, Prop } from 'vue-property-decorator';
import { FormDefinition, FormSubmissionValidationError, FormField, FormValue } from 'client-website-ts-library/types/Forms';
import {
  AutosaveManager,
  ServiceManager,
  ServiceType,
  API,
  Config,
  Logger,
  LogLevel,
  ReCaptchaService,
} from 'client-website-ts-library/services';
import { Context } from 'client-website-ts-library/types';
import { PDFFieldData } from 'client-website-ts-library/types/Forms/FieldTypes';
import { IRequestProgressDelegate } from 'client-website-ts-library/services/API/IRequestProgressDelegate';

import Section from './Section.vue';
import Loader from '../UI/Loader.vue';

@Component({
  components: {
    Section,
    Loader,
  },
})
export default class Form extends Vue implements IRequestProgressDelegate {
  @Prop({ default: () => ({ Items: [] }) })
  private readonly context!: Context;

  @Prop()
  private readonly type!: string;

  @Prop()
  private readonly submitButtonColour!: string;

  @Prop()
  private readonly submitButtonBackgroundColour!: string;

  @Prop()
  private readonly submitButtonClass!: string;

  @Prop()
  private readonly autosaveNamespace!: string;

  @Prop()
  private readonly enableAutosave!: boolean;

  @Prop({ default: 'var(--form-field-bg)' })
  private readonly fieldBg!: string;

  @Prop({ default: 'var(--form-field-colour)' })
  private readonly fieldColour!: string;

  @Prop({ default: 'var(--form-label-colour)' })
  private readonly labelColour!: string;

  @Prop({ default: 'var(--form-field-border-colour)' })
  private readonly borderColour!: string;

  @Prop({ default: 'var(--form-label-focused-bg)' })
  private readonly labelFocusedBg!: string;

  @Prop({ default: 'var(--form-label-focused-colour)' })
  private readonly labelFocusedColour!: string;

  @Prop({ default: '#eee' })
  private readonly underlineInactive!: string;

  @Prop({ default: 'var(--brand-primary)' })
  private readonly underlineActive!: string;

  @Prop({ default: true })
  private readonly includeRecaptcha!: boolean;

  private ctx: Context = this.context;

  private errored = false;

  private submitted = false;

  private errors: FormSubmissionValidationError[] = [];

  private loading = true;

  private definition: FormDefinition | null = null;

  private autosaveManager: AutosaveManager = ServiceManager.Require<AutosaveManager>(ServiceType.FormAutosave);

  private hasAutosave = false;

  private autosaveDate: string | null = null;

  private submitPercent = 0;

  private recaptchaService = ServiceManager.Require(ServiceType.ReCaptcha) as ReCaptchaService;

  private propertyAdressFormFieldValue: string | null = null;

  get formStyle() {
    return {
      '--field-bg': this.fieldBg,
      '--field-colour': this.fieldColour,
      '--border-colour': this.borderColour,
      '--label-colour': this.labelColour,
      '--label-focused-bg': this.labelFocusedBg,
      '--label-focused-colour': this.labelFocusedColour,
      '--underline-inactive': this.underlineInactive,
      '--underline-active': this.underlineActive,
    };
  }

  onProgress(progress: number, total: number) {
    this.submitPercent = Math.round((progress / total) * 100);

    console.log(this.submitPercent);
  }

  mounted() {
    if (this.ctx === null) {
      this.ctx = {
        Items: [],
        ClientWebsiteId: Config.Website.Id,
      };
    }

    if (this.ctx.ClientWebsiteId === undefined) {
      this.ctx.ClientWebsiteId = Config.Website.Id;
    }

    API.Forms.GetForm(this.type, this.context).then((def) => {
      this.loading = false;
      this.definition = def;

      if (this.enableAutosave) {
        const state = this.autosaveManager.GetState(this.type);

        if (state !== null) {
          this.hasAutosave = true;
        }
      }
    }).catch(() => {
      this.errored = true;
    });
  }

  loadAutosave(): void {
    if (!this.hasAutosave) return;

    const state = this.autosaveManager.GetState(this.type);

    if (state === null) return;

    this.definition!.Sections.forEach((section) => {
      const sectionComponent = (this.$refs[section.SectionId] as Vue[])[0] as Section;

      sectionComponent.setData(state);
    });
  }

  private handleFieldInput(fieldDef: FormField): void {
    this.errors = this.errors.filter((err) => err.Key !== fieldDef.Key);
  }

  submitForm(): void {
    if (this.loading) return;

    this.loading = true;

    this.errors = [];

    this.collectData(this.includeRecaptcha).then((data) => {
      const normalisedData = Form.normaliseData(data);

      console.log(normalisedData);

      this.loading = false;

      // this.context.ClientWebsiteId = Config.Website.Id;

      // if (this.enableAutosave) this.autosaveManager.SetState(this.type, data);

      API.Forms.SubmitForm(this.type, this.context, normalisedData).then((result) => {
        this.loading = false;

        if (!result.Valid) {
          // Reset ReCaptcha
          this.resetRecaptcha();

          // Update errors
          this.errors = result.ValidationState.Errors;
          this.errored = true;
        } else if (result.Submitted) {
          // Inform the user that the form was submitted
          this.submitted = true;

          // this.$router.push({ path: '/thank-you' });

          this.$emit('submitted', result.Result);
        }
      }).catch(() => {
        // Reset ReCaptcha
        this.resetRecaptcha();

        // Inform the user that there was an error
        this.errored = true;
      }).finally(() => {
        this.loading = false;
      });
    });
  }

  private static normaliseData(data: FormValue[]): FormValue[] {
    const newValues: FormValue[] = [];

    data.forEach((entry) => {
      if (entry.Value instanceof Array) {
        if (entry.Value.length) {
          newValues.push({
            Key: `${entry.Key}_length`,
            Value: entry.Value.length,
          });

          for (let i = 0; i < entry.Value.length; i += 1) {
            newValues.push({
              Key: `${entry.Key}_${i}`,
              Value: entry.Value[i],
            });
          }
        }
      } else {
        newValues.push(entry);
      }
    });

    return newValues;
  }

  private collectData(includeRecaptcha: boolean): Promise<FormValue[]> {
    return new Promise((resolve, reject) => {
      const data: FormValue[] = [];

      // Loop through each form secction and collect the data from it
      this.definition!.Sections.forEach((section) => {
        const sectionComponent = (this.$refs[section.SectionId] as Vue[])[0] as Section;

        data.push(...sectionComponent.collectData());
      });

      // If we also need to submit the ReCaptcha response, make sure we call getResponse on the ReCaptcha component
      if (includeRecaptcha) {
        setTimeout(() => {
          this.loading = false;
        }, 1000);

        this.getRecaptchaResponse().then((recaptchaResponse: string) => {
          Logger.Log(LogLevel.Debug, '[Form]', 'Got ReCaptcha response');

          data.push({
            Key: '_recaptcha',
            Value: recaptchaResponse,
          });

          resolve(data);
        }).catch(reject);

        return;
      }

      // If we don't need ReCaptcha, resolve straight away
      resolve(data);
    });
  }

  private resetRecaptcha(): void {
    this.recaptchaService.Reset();
  }

  private getRecaptchaResponse(): Promise<string> {
    return this.recaptchaService.GetResponse();
  }
}
